<template>
  <div class="side">
    <div class="tree">
      <div class="first" v-for="(v, k) in sideTreeList">
        <div :class="k == checkNum ? 'title checked' : 'title'" @click="checkedFn(v, k)">
          <img :src="require(`@/assets/img/terminal/${k == checkNum ? 't' : 'noT'}reeNode.svg`)" />
          <p>{{ v.label }}</p>
        </div>
        <ul v-show="checkNum == k">
          <li :class="detailNum == i ? 'detailBg' : ''" v-for="(j, i) in v.children" @click="openDetail(j, i)">{{ j.label }}</li>
        </ul>
      </div>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: 'side',
  data() {
    return {
      checkNum: 0,
      detailNum: 0,
    }
  },
  props: ['sideTreeList'],
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    checkedFn(v, k) {
      if (this.checkNum == k) return
      this.checkNum = k
      this.detailNum = 0
      this.$emit('onClick', v.children[0])
    },
    openDetail(j, i) {
      this.detailNum = i
      this.$emit('onClick', j)
    },
  },
}
</script>

<style lang="less">
.side {
  position: relative;
  .tree {
    margin-top: 1.08rem;
    .first {
      font-size: 0.18rem;
      color: #333333;
      margin-bottom: 0.64rem;
      .title {
        display: flex;
        cursor: pointer;
        align-items: center;
        img {
          width: 0.14rem;
          height: 0.14rem;
          margin-right: 0.07rem;
          z-index: 10;
        }
        p {
          word-break: keep-all;
        }
      }
      .checked {
        color: #00a4ea;
      }
      ul {
        padding-left: 0.17rem;
        li {
          padding-left: 0.13rem;
          font-size: 0.16rem;
          // width: 1.26rem;
          height: 0.3rem;
          line-height: 0.3rem;
          margin-top: 0.36rem;
          cursor: pointer;
          &:hover {
            color: #00a4ea;
          }
        }
        .detailBg {
          background-image: url('../assets/img/terminal/detailBg.svg');
          background-size: cover;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .line {
    width: 0.04rem;
    height: 2rem;
    background: #c9cdd4;
    border-radius: 0rem 0rem 0rem 0rem;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0.05rem;
    height: 100%;
  }
}
</style>
