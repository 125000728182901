<template>
  <div class="smartMedicalInsurance">
    <div class="banner" ref="banner">
      <img src="@/assets/img/smartMedicalInsurance/banner.png" />
    </div>
    <div class="terminalContent">
      <div class="side">
        <div class="tree">
          <div class="first" v-for="(v, k) in sideTreeList">
            <div class="title checked">
              <img :src="require(`@/assets/img/terminal/treeNode.svg`)" />
              <p>{{ v.label }}</p>
            </div>
            <ul>
              <li :class="checkIndex == i ? 'detailBg' : ''" v-for="(j, i) in v.children" @click="openDetail(j, i)">
                {{ j.label }}
              </li>
            </ul>
          </div>
        </div>
        <div class="line"></div>
      </div>
      <div class="right">
        <div class="details">
          <div class="banner">
            <img :src="currentSideItem.bannerSrc" />
          </div>
          <div class="advantage">
            <p>{{ currentSideItem.description }}</p>
          </div>
          <div class="product" v-if="currentSideItem.productSrc">
            <img :src="currentSideItem.productSrc" />
          </div>
          <h2>产品功能</h2>
          <div class="function">
            <div class="functionItem" v-for="item in currentSideItem.functionList">
              <img :src="item.img" alt="" />
              <p>{{ item.title }}</p>
            </div>
          </div>
          <h2>产品特点</h2>
          <div class="character">
            <div class="characterItem" v-for="item in currentSideItem.characterList">
              <img :src="item.img" alt="" />
              <p>{{ item.desc }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { smartMedicalInsuranceList } from '@/assets/data/smartMedicalInsurance'
import side from '@/components/side.vue'
export default {
  name: 'SmartMedicalInsurance',
  components: {
    side,
  },
  computed: {
    sideTreeList() {
      return smartMedicalInsuranceList
    },
  },
  watch: {
    $route(to, from) {
      if (to.path === '/smartMedicalInsurance') {
        this.checkIndex = this.sideTreeList[0].children.findIndex((v) => v.label == to.query.name)
        this.checkIndex = this.checkIndex === -1 ? 0 : this.checkIndex
        this.currentSideItem = this.sideTreeList[0].children[this.checkIndex]
      }
    },
  },
  data() {
    return {
      checkIndex: 0,
      currentSideItem: {},
    }
  },
  methods: {
    openDetail(j, i) {
      this.checkIndex = i
      this.currentItem = this.sideTreeList[0].children[i]
      this.$router.push({
        path: '/smartMedicalInsurance',
        query: {
          name: j.label,
        },
      })
      this.handleSlide()
    },
    handleSlide() {
      window.scrollTo({
        top: this.$refs.banner.clientHeight,
        left: 0,
        behavior: 'smooth',
      })
    },
  },
  created() {
    this.checkIndex = this.sideTreeList[0].children.findIndex((v) => v.label == this.$route.query.name)
    if (this.checkIndex == -1) {
      this.checkIndex = 0
      this.$router.push('/smartMedicalInsurance')
    }
    this.currentSideItem = this.sideTreeList[0].children[this.checkIndex]
  },
  mounted() {},
}
</script>
<style scoped lang="less">
.smartMedicalInsurance {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #fff;
  .banner {
    width: 100%;
    height: 3.9rem;
    position: relative;
    background: #eceaec;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .terminalContent {
    padding: 0 3.57rem 0.6rem;
    display: flex;
    justify-content: space-between;
    .side {
      position: relative;
      .tree {
        z-index: 2;
        position: sticky;
        top: 1.78rem;
        margin-top: 1.08rem;
        .first {
          font-size: 0.18rem;
          color: #333333;
          margin-bottom: 0.4rem;

          .title {
            display: flex;
            cursor: pointer;
            align-items: center;

            img {
              width: 0.14rem;
              height: 0.14rem;
              margin-right: 0.07rem;
              z-index: 10;
            }
          }
          .checked {
            color: #00a4ea;
          }
          ul {
            padding-left: 0.17rem;
            li {
              padding-left: 0.13rem;
              font-size: 0.16rem;
              width: 1.26rem;
              height: 0.3rem;
              line-height: 0.3rem;
              margin-top: 0.2rem;
              cursor: pointer;
              word-break: keep-all;
              &:hover {
                color: #00a4ea;
              }
            }
            .detailBg {
              background-image: url('../assets/img/terminal/detailBg.svg');
              background-size: cover;
              color: #fff;
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }
      .line {
        z-index: 1;
        width: 0.04rem;
        height: 2rem;
        background: #c9cdd4;
        border-radius: 0rem 0rem 0rem 0rem;
        position: absolute;
        top: 0;
        left: 0.05rem;
        height: 100%;
      }
    }
    .right {
      flex: 1;
      margin-left: 0.5rem;
      .details {
        .banner,
        .product {
          width: 100%;
          margin-top: 0.8rem;
          display: flex;
          align-items: center;
          img {
            width: 100%;
          }
        }
        h2 {
          margin: 0.5rem 0 0.2rem 0;
          color: #333333;
          font-size: 0.28rem;
        }
        .advantage {
          width: 9rem;
          margin-top: 0.5rem;
          font-size: 0.18rem;
          line-height: 0.4rem;
          color: #666;
        }
        .function {
          display: grid;
          grid-template-columns: 2.2rem 2.2rem 2.2rem 2.2rem;
          grid-gap: 0.2rem 0.22rem;
          .functionItem {
            height: 2.6rem;
            background: #ffffff;
            border-radius: 0.04rem;
            opacity: 1;
            border: 0.01rem solid #e5e8ef;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // cursor: pointer;
            transition: box-shadow 0.4s;
            &:hover {
              box-shadow: 0.04rem 0.04rem 0.1rem 0.01rem rgba(0, 0, 0, 0.1);
              p {
                color: #00a4ea;
              }
            }
            img {
              width: 0.9rem;
              height: 0.9rem;
            }
            p {
              font-size: 0.2rem;
              font-weight: bold;
              color: #666666;
              line-height: 0.28rem;
              margin-top: 0.2rem;
            }
          }
        }
        .character {
          display: grid;
          grid-template-columns: 3rem 3rem 3rem;
          grid-gap: 0.2rem 0.22rem;
          .characterItem {
            height: 2.6rem;
            background: #ffffff;
            border-radius: 0.04rem;
            opacity: 1;
            border: 0.01rem solid #e5e8ef;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 0.24rem;
            box-sizing: border-box;
            img {
              width: 0.9rem;
              height: 0.9rem;
              margin: 0.4rem 0 0.2rem;
            }
            p {
              font-size: 0.16rem;
              font-weight: 400;
              color: #666666;
              line-height: 0.24rem;
            }
          }
        }
      }
    }
  }
}
</style>
